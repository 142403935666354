import React from 'react';

export type ESGThemes = 'Environmental' | 'Social' | 'Governance';

export const tailwindJITLoadHack = () => {
    // Tailwind checks for classes in the DOM before rendering
    // but because the function below generates the string
    // it doesn't know which classes to load, this loads them.
    return (
        <div
            className="hidden 
            border-blue-500 bg-blue-500 text-blue-500
            border-yellow-500 bg-yellow-500 text-yellow-500
            border-purple-500 bg-purple-500 text-purple-500
            bg-blue-100 bg-yellow-100 bg-purple-100
            "
        ></div>
    );
};

const accentByParentTheme = (parentTheme: ESGThemes, isText = false, isBorder = false): string => {
    let themeColor = '';
    if (parentTheme === 'Environmental') {
        themeColor = 'blue-500';
    } else if (parentTheme === 'Social') {
        themeColor = 'yellow-500';
    } else if (parentTheme === 'Governance') {
        themeColor = 'purple-500';
    }
    if (isText) {
        return `text-${themeColor}`;
    } else if (isBorder) {
        return `border-${themeColor}`;
    } else {
        return themeColor;
    }
};

export const accentByParentThemeLight = (parentTheme: ESGThemes, isText = false, isBorder = false): string => {
    let themeColor = '';
    if (parentTheme === 'Environmental') {
        themeColor = 'blue-100';
    } else if (parentTheme === 'Social') {
        themeColor = 'yellow-100';
    } else if (parentTheme === 'Governance') {
        themeColor = 'purple-100';
    }
    if (isText) {
        return `text-${themeColor}`;
    } else if (isBorder) {
        return `border-${themeColor}`;
    } else {
        return themeColor;
    }
};

export default accentByParentTheme;
