import React from 'react';

interface IconMap {
    [platformName: string]: {
        icon: string;
        color: string;
    };
}

const PlatformIcon = ({ platform, className }: { platform: string; className?: string }): JSX.Element => {
    const iconMap: IconMap = {
        twitter: {
            icon: 'fab fa-twitter',
            color: '#1DA1F2',
        },
        youtube: {
            icon: 'fab fa-youtube',
            color: '#FF0000',
        },
        instagram: {
            icon: 'fab fa-instagram',
            color: '#E1306C',
        },
        linkedin: {
            icon: 'fab fa-linkedin',
            color: '#2867B2',
        },
        facebook: {
            icon: 'fab fa-facebook',
            color: '#1877F2',
        },
        rssfeed: {
            icon: 'fas fa-rss',
            color: '#2867B2',
        },
        website: {
            icon: 'fas fa-globe',
            color: '#2867B2',
        },
        blog: {
            icon: 'fas fa-blog',
            color: '#2867B2',
        },
    };
    if (platform && iconMap[platform.toLowerCase()]) {
        const icon = iconMap[platform.toLowerCase()];
        return <i className={`${icon.icon} ${className}`} style={{ color: icon.color }}></i>;
    } else {
        return <></>;
    }
};

export default PlatformIcon;
