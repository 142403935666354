import React from 'react';
import Loader from 'react-loader-spinner';

interface ComponentProps {
    className?: string;
    height?: number;
    width?: number;
}

const Spinner = ({ className, height, width }: ComponentProps): JSX.Element => {
    return (
        <div className={className ? className : 'flex flew-row w-full justify-center py-10'}>
            <Loader type="TailSpin" color="#159A1F" height={height ? height : 80} width={width ? width : 80} />
        </div>
    );
};

export default Spinner;
