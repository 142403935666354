import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import accentByParentTheme, { ESGThemes } from '~/components/themeAccents';

interface ComponentProps {
    themeName: string;
    parentTheme: ESGThemes;
    className?: string;
}

const ThemePillWithoutLink = (props: ComponentProps): JSX.Element => {
    const [accentWidth, setAccentWidth] = useState<string>('w-4');
    const { themeName, parentTheme, className } = props;

    return (
        <p
            className={twMerge(
                `px-2 py-1 font-primary tracking-wide font-size-16 font-semibold text-black hover:text-black background-offset rounded-lg text-center flex flex-col`,
                className,
            )}
            onMouseOver={() => setAccentWidth('w-full')}
            onMouseOut={() => setAccentWidth('w-4')}
        >
            {themeName}
            <span
                className={`border-b-2 ${accentByParentTheme(
                    parentTheme,
                    false,
                    true,
                )} ${accentWidth} -mt-1 transition-all duration-500 ease-in-out`}
            ></span>
        </p>
    );
};

export default ThemePillWithoutLink;
