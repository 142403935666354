import React, { Fragment } from 'react';
import Spinner from './spinner';

interface LoadingProps {
    isLoading?: boolean;
}

const Loading = (props: LoadingProps): JSX.Element => {
    let { isLoading } = props;

    if (isLoading == null) {
        isLoading = true;
    }

    return (
        <Fragment>
            {isLoading && (
                <div className="spin-container">
                    <Spinner />
                </div>
            )}
        </Fragment>
    );
};

export default Loading;
