import { toast } from 'react-toastify';
import csrfToken from '~/utils/csrf';

export const showNotificationAfterApiCall = (
    response: any,
    successText = 'Success',
    errorText = 'An unknown error occurred, please try again.',
): void => {
    if (response && !response.error) {
        toast.success(successText);
    } else {
        toast.error(response?.error ? response?.error : errorText);
    }
};

enum HTTPMethods {
    GET = 'GET',
    CREATE = 'POST',
    UPDATE = 'PUT',
    DELETE = 'DELETE',
}

class ApiClient {
    baseUrl: string;
    headers: {
        'Content-Type'?: string;
        Accept?: string;
        'X-CSRF-Token'?: string;
    };

    constructor(isFileUpload?: boolean) {
        this.baseUrl = `${window.location.origin}`;
        this.headers = isFileUpload
            ? { 'X-CSRF-Token': csrfToken }
            : {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  'X-CSRF-Token': csrfToken,
              };
    }

    private async fetchClient(
        url: string,
        method: HTTPMethods,
        dataForBody?: { [key: string]: unknown },
        formDataForBody?: FormData,
    ) {
        if (!url.startsWith('/')) {
            throw new Error('URL must start with a slash (/)');
        }

        const fullUrl = this.baseUrl + url;
        const bodyData = formDataForBody || (dataForBody ? JSON.stringify(dataForBody) : undefined);
        const fetchTransaction = await fetch(fullUrl, {
            method: method,
            headers: this.headers,
            body: bodyData,
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                return { error: `${error}` };
            });
        return fetchTransaction;
    }

    private appendFormData(formData: FormData, key: string, value: any, prefixKey?: string): FormData {
        const keyName = prefixKey ? `${prefixKey}[${key}]` : key;
        if (value instanceof File) {
            formData.append(keyName, value, value.name);
        } else {
            formData.append(keyName, value);
        }
        return formData;
    }

    private buildFormData(data: { [key: string]: any }): FormData {
        let formData = new FormData();
        Object.keys(data).forEach(key => {
            if (Object.keys(data[key])?.length) {
                Object.keys(data[key]).forEach(childKey => {
                    formData = this.appendFormData(formData, childKey, data[key][childKey], key);
                });
            } else {
                formData = this.appendFormData(formData, key, data[key]);
            }
        });
        return formData;
    }

    private fetchFileBlob(
        url: string,
        method: HTTPMethods.CREATE,
        dataForBody?: { [key: string]: unknown },
    ): Promise<Blob | { error: string }> {
        if (!url.startsWith('/')) {
            throw new Error('URL must start with a slash (/)');
        }

        const fullUrl = this.baseUrl + url;
        const bodyData = dataForBody ? JSON.stringify(dataForBody) : undefined;
        return fetch(fullUrl, {
            method: method,
            headers: this.headers,
            body: bodyData,
        })
            .then(response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(blob => {
                return blob;
            })
            .catch(error => {
                return { error: `${error}` };
            });
    }

    async get(url: string): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.GET);
    }

    async create(url: string, dataToPost: { [key: string]: unknown }): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.CREATE, dataToPost);
    }

    async update(url: string, dataToPut: { [key: string]: unknown }): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.UPDATE, dataToPut);
    }

    async delete(url: string): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.DELETE);
    }

    async createWithFile(url: string, dataToPost: { [key: string]: unknown }): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.CREATE, undefined, this.buildFormData(dataToPost));
    }

    async updateWithFile(url: string, dataToPut: { [key: string]: unknown }): Promise<any> {
        return await this.fetchClient(url, HTTPMethods.UPDATE, undefined, this.buildFormData(dataToPut));
    }
    async export(url: string, dataToPost: { [key: string]: unknown }): Promise<any> {
        return await this.fetchFileBlob(url, HTTPMethods.CREATE, dataToPost);
    }
}

export const ApiUploadClient = new ApiClient(true);
export default new ApiClient();
